<template>
    <el-dialog class="dialogForm dialogTable" :title="title" width="70%" :close-on-click-modal="false" append-to-body
               :visible.sync="visible">
        <el-form class="recordCollect" size="small" :inline="true" :model="inputForm" :rules="dataRule" ref="inputForm"
                 label-width="180px" :disabled="method==='view'" v-loading="loading">
            <el-form-item label="借阅人：" prop="borrowUserName">
                <el-input v-model.trim="inputForm.borrowUserName" maxlength="20"
                          placeholder="请输入借阅人姓名（限20字）"></el-input>
            </el-form-item>
            <el-form-item label="手机：" prop="telPhone">
                <el-input v-model.trim="inputForm.telPhone" maxlength="11" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="借阅人机构：" prop="unitName">
                <el-input v-model.trim="inputForm.unitName" maxlength="30" placeholder="请输入借阅人机构（限30字）"></el-input>
            </el-form-item>
            <el-form-item label="借出日期：" prop="lendTime">{{inputForm.lendTime}}</el-form-item>
            <el-form-item label="预计归还日期：" prop="deadline">
                <el-date-picker class="w100i"
                                v-model="inputForm.deadline"
                                type="date"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="借阅目的：" prop="borrowPurpose">
                <el-select v-model="inputForm.borrowPurpose" placeholder="请选择借阅目的" clearable class="w100i">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('borrow_purpose')">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="借阅经办人：" prop="createName">{{inputForm.createName}}</el-form-item>
            <el-form-item label="状态：" prop="returnState" v-if="method != 'add'">{{$dictUtils.getDictLabel("book_borrow_return", inputForm.returnState ,'')}}</el-form-item>
            <el-form-item label="备注：" prop="remark" class="rowBox">
                <el-input
                        maxlength="200"
                        type="textarea"
                        :rows="2"
                        placeholder="请输入备注信息。（限200字）"
                        v-model="inputForm.remark">
                </el-input>
            </el-form-item>
            <el-descriptions title="借阅图书"></el-descriptions>
            <el-form-item label="图书：" prop="bookList" class="rowBox">
                <el-button size="small" type="primary" @click="selBook()" v-if="method != 'view'">请选择</el-button>
                <el-table :data="inputForm.bookList" size="small" max-height="300" class="table m_t1"
                          v-if="inputForm.bookList.length">
                    <el-table-column prop="areaCode" label="区域编号" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="bookName" label="书名" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="publishingHouse" label="出版社" show-overflow-tooltip></el-table-column>
                    <el-table-column fixed="right" width="180" label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="view(scope.row, 'view')" :disabled="false">
                                详情
                            </el-button>
                            <el-button type="text" size="small" v-if="method != 'view'"
                                       @click="del(scope.$index)">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>
        </el-form>
        <div slot="footer" class="text_center">
            <el-button size="small" @click="visible = false">{{method == 'view' ? '关闭' : '取消'}}</el-button>
            <el-button size="small" type="primary" @click="doSubmit()" v-if="method != 'view'" v-noMoreClick>保存
            </el-button>
        </div>
        <ManageForm ref="manageForm"></ManageForm>
        <SelectBookList ref="selectBookList" @getDataList="getBookList"></SelectBookList>
    </el-dialog>
</template>

<script>
    import ManageForm from './manageForm'
    import SelectBookList from './selectBookList'

    export default {
        components: {ManageForm, SelectBookList},
        data() {
            // 校验预计归还日期
            var validatorDeadline = (rule, value, callback) => {
                if (value && new Date(value) < new Date(this.inputForm.lendTime)) {
                    callback(new Error('预计归还日期必须大于或等于借出日期'));
                    return
                }
                callback();
            };
            return {
                title: '',
                visible: false,
                method: '',
                loading: false,
                inputForm: {
                    id: '',
                    borrowUserName: '',
                    telPhone: '',
                    unitName: '',
                    lendTime: this.$publicUtile.formatDate(null, 1),
                    deadline: '',
                    borrowPurpose: '',
                    createName: JSON.parse(sessionStorage.getItem('userInfo')).name,
                    createBy: JSON.parse(sessionStorage.getItem('userInfo')).id,
                    returnState: '1',
                    remark: '',
                    bookList: [],
                },
                dataRule: {
                    borrowUserName: [
                        {required: true, message: '借阅人不能为空', trigger: 'blur'}
                    ],
                    telPhone: [
                        {required: true, message: '手机号不能为空', trigger: 'blur'},
                        {validator: this.validator.isMobile, trigger: 'blur'}
                    ],
                    deadline: [
                        {required: true, message: '预计归还日期不能为空', trigger: 'change'},
                        {validator: validatorDeadline, trigger: 'change'}
                    ],
                    bookList: [
                        {required: true, message: '借阅图书不能为空', trigger: 'change'}
                    ],
                }
            }
        },
        methods: {
            init(row, method) {
                this.loading = false
                this.inputForm.returnState = '1'
                if (row) {
                    this.inputForm.id = row.id
                } else {
                    this.inputForm.id = ''
                }
                this.method = method
                if (method === 'add') {
                    this.title = '图书借阅申请'
                } else if (method === 'edit') {
                    this.title = '修改借阅申请'
                } else if (method === 'view') {
                    this.title = '查看借阅申请'
                }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    if (method !== 'add') {
                        this.getInfo()
                    }
                })
            },
            getInfo() {
                this.loading = true
                this.$axios(this.api.record.bookborrowGetById + '/' + this.inputForm.id).then(data => {
                    if (data.status) {
                        this.recover(this.inputForm, data.data.bookBorrow);
                        this.$set(this.inputForm, 'bookList', data.data.archivesBookList)
                        this.loading = false
                    }
                })
            },
            // 选择图书
            selBook() {
                this.$refs.selectBookList.init(this.inputForm.bookList, 10)
            },
            // 获取选择的图书数据
            getBookList(data) {
                data.map(item => {
                    this.inputForm.bookList.push(item)
                })
                if (this.inputForm.bookList.length) {
                    this.$refs['inputForm'].clearValidate(['bookList']);
                }
            },
            // 详情
            view(row, method) {
                if (row.delFlag == 1) {
                    this.$message.error('该图书已删除！')
                    return
                }
                this.$refs.manageForm.init(row, method)
            },
            // 删除图书
            del(index) {
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.inputForm.bookList.splice(index, 1);
                    this.$message.success('删除成功')
                })
            },
            // 提交
            doSubmit() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let api = this.api.record.bookborrowSave, type = 'post'
                        if (this.method === 'edit') {  // 修改
                            api = this.api.record.bookborrowUpdateById
                            type = 'put'
                        }
                        let p = JSON.parse(JSON.stringify(this.inputForm))
                        delete p.bookList
                        this.$axios(api, {
                            bookBorrow: p,
                            archivesBookList: this.inputForm.bookList
                        }, type).then(data => {
                            if (data.status) {
                                this.$message.success('保存成功')
                                this.visible = false
                                this.$emit('refreshDataList')
                            } else {
                                this.$message.error('保存失败')
                            }
                            this.loading = false
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .rowBox {
        width: 97% !important;
    }
</style>
